.recommendationContainer{
    
}

.recommendationIcon{
    margin-top: 4px !important;
}

.recommendationTypo{
    flex: 1;
    text-align: left;
}