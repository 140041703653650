.headerToolbar{
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.headerTitle {
    display: flex;
    padding: 8px 0px;
    height: 48px !important;
    align-items: center;
    flex: 1 0 0;
    justify-content: space-between;
    /* align-items: flex-start; */
    align-self: stretch;
}

.headerDescription {
    align-self: stretch;
}

.actionContainer{
    display: flex;
    /* gap: 12px; */
}

.footerContainer{
    width: 100% !important;
    text-align: center !important;
    margin-bottom: 20px;
}