body{
  margin: 0;
  font-family: 'Noto Sans';
  color: #221A1A !important;
}

button {
  text-transform: none !important;
}

.margin-top-10{
  margin-top: 10px !important;
}

.margin-top-20{
  margin-top: 20px !important;
}
