.indicateTypo{
    flex: 1;
    padding: 8px 12px;
    text-align: left;
}

.backIcon{
    width: 24px !important;
}

.nextIcon{
    width: 24px !important;
}

.aboutTitle{
    display: flex;
    margin-bottom: 16px !important;
    gap: 16px !important;
    align-items: center;
}

.aboutFactor{
    display: flex;
    margin-top: 16px !important;
    gap: 16px !important;
    align-items: center;
}

.factorContainer{
    display: flex;
    padding: 16px 0px !important;
    gap: 12px;
    flex-wrap: wrap;
    justify-content: left;
}